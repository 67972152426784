import "./App.css";
import { Switch, Route, useRouteMatch } from "react-router-dom";
// components
import Header from "components/Header";
// import Footer from 'components/Footer';
// pages
import PocketExamPage from "pages/PocketExamPage";
import PageNotFound from "pages/PageNotFound";
import FooterPocketExam from "pages/PocketExamPage/FooterPocketExam";
import CourseExamPage from "pages/CourseExamPage";
import CourseDetailsPage from "pages/CourseDetailsPage";
import SubmitFeedbackPage from "pages/SubmitFeedbackPage";
import ReviewAnswerPage from "pages/ReviewAnswerPage";
import Login from "pages/AuthenticationPage/Login";
import Register from "pages/AuthenticationPage/Register";
import Verify from "pages/AuthenticationPage/Verify";
import RegistrationComplete from "pages/AuthenticationPage/RegistrationComplete";
import ForgotPassword from "pages/AuthenticationPage/ForgotPassword";
import ChangePassword from "pages/AuthenticationPage/ChangePassword";
import PublicRoute from "hoc/PublicRoute";
import { useGetUserPreferenceQuery } from "app/services/userPreferenceApi";
import { useDispatch, useSelector } from "react-redux";
import { Suspense, useEffect } from "react";
import { setUserPreference } from "app/features/userPreferenceSlice";
import AboutUsPage from "pages/AboutUsPage/AboutUsPage";
import PrivacyPolicyPage from "pages/PrivacyPolicy/PrivacyPolicyPage";
import PrivacyPolicyPageDrivingLicense from "pages/PrivacyPolicy/PrivacyPolicyPageDrivingLicense";
import {
  loginSelector,
  setAuth,
} from "pages/AuthenticationPage/Login/LoginSlice";
import { useGetUserProfileQuery } from "app/services/userProfileApi";
import TokenExpiredPage from "pages/TokenExpired/TokenExpiredPage";
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy";
import TermsOfUsePage from "pages/TermsOfUse/TermsOfUsePage";

function App() {
  const { data } = useGetUserPreferenceQuery();
  const { isAuthenticated } = useSelector(loginSelector);
  // fetch current user profile data
  const { data: userData } = useGetUserProfileQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(setUserPreference(data));
    }
    if (isAuthenticated) {
      dispatch(setAuth(userData));
    }
  }, [data, dispatch, isAuthenticated, userData]);

  let isExamPage = useRouteMatch({
    path: "/exam",
    strict: true,
    sensitive: true,
  });

  return (
    <>
      <Header />
      <div className="app-body">
        {/* <ErrorBoundary FallbackComponent={Error500Page}> */}
        <Suspense fallback={<></>}>
          <Switch>
            <Route
              exact
              path="/login"
              render={() => (
                <PublicRoute>
                  <Login />
                </PublicRoute>
              )}
            />

            <Route
              exact
              path="/register"
              render={() => (
                <PublicRoute>
                  <Register />
                </PublicRoute>
              )}
            />
            <Route
              exact
              path="/verify"
              render={() => (
                <PublicRoute>
                  <Verify />
                </PublicRoute>
              )}
            />
            <Route
              exact
              path="/registration-complete"
              render={() => (
                <PublicRoute>
                  <RegistrationComplete />
                </PublicRoute>
              )}
            />
            <Route
              exact
              path="/forgot-password"
              render={() => (
                <PublicRoute>
                  <ForgotPassword />
                </PublicRoute>
              )}
            />
            <Route
              exact
              path="/failedactivation"
              component={TokenExpiredPage}
            />
            <Route
              exact
              path="/registrationComplete"
              component={RegistrationComplete}
            />
            <Route
              exact
              path="/changeNewPassword/:token"
              render={() => (
                <PublicRoute>
                  <ChangePassword />
                </PublicRoute>
              )}
            />
            {/*
              <Route
                exact
                path='/failedactivation'
                component={TokenExpiredPage}
              /> */}
            <Route exact path="/pocket-exam/:path" component={PocketExamPage} />
            <Route exact path="/exam-details" component={CourseDetailsPage} />
            <Route exact path="/exam" component={CourseExamPage} />
            <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route
              exact
              path="/privacy-policy-driving-license"
              component={PrivacyPolicyPageDrivingLicense}
            />
            <Route exact path="/aboutus" component={AboutUsPage} />
            <Route
              exact
              path="/termsandconditions"
              component={TermsOfUsePage}
            />
            <Route exact path="/quiz/result" component={SubmitFeedbackPage} />
            <Route
              exact
              path="/quiz/review/:slug"
              component={ReviewAnswerPage}
            />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Suspense>
      </div>
      {/* <Footer /> */}
      {isExamPage ? null : <FooterPocketExam />}
    </>
  );
}

export default App;
