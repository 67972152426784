import React from 'react';
import FavouriteIcon from 'assets/icons/Favorite.svg';
import FavoriteHover from './FavoriteHover';
import MenuDropdown from 'common/MenuDropdown';
// css
import './index.scss';

function Favorite() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className='favorite'>
        <div onClick={handleOpen} ref={anchorRef}>
          <img src={FavouriteIcon} alt='Favourite Icon' />
        </div>

        <MenuDropdown
          favorite='true'
          open={open}
          setOpen={setOpen}
          anchorRef={anchorRef}
        >
          <FavoriteHover handleClose={handleClose} />
        </MenuDropdown>
      </div>
    </>
  );
}

export default Favorite;
