import React from "react";
import SubHeader from "../SubHeader/SubHeader";

import { Wrapper } from "./PrivacyPolicyElements";

const PrivacyPolicy = ({ name }) => {
  return (
    <>
      <SubHeader title="Privacy policy" date="August 25,2021" />

      <Wrapper>
        {/* PRIVACY & POLICY */}
        <div className="policy__title">Privacy Policy</div>
        <div className="policy__list">
          <dd>
            Growth Lab Technology & Innovations Pvt. Ltd. built the {name}
            app as a Free app. This SERVICE is provided by Growth Lab Technology
            & Innovations Pvt. Ltd. at no cost and is intended for use as is.
            This page is used to inform visitors regarding our policies with the
            collection, use, and disclosure of Personal Information if anyone
            decided to use our Service. If you choose to use our Service, then
            you agree to the collection and use of information in relation to
            this policy. The Personal Information that we collect is used for
            providing and improving the Service. We will not use or share your
            information with anyone except as described in this Privacy Policy.
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which is accessible at {name} unless
            otherwise defined in this Privacy Policy.
          </dd>
        </div>
        {/* INFORMATION COLLECTION AND USE */}
        <div className="policy__title">Information Collection and Use</div>
        <div className="policy__list">
          <dd>
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to Device Identifier, Username, Phone
            Number, Email, User Image . The information that we request will be
            retained by us and used as described in this privacy policy. The app
            does use third party services that may collect information used to
            identify you.<br></br>
            Link to privacy policy of third party service providers used by the
            app.
            <ul>
              <li>Google Play Services</li>
              <li>Google Analytics for Firebase</li>
              <li>Firebase Crashlytics</li>
              <li>Facebook</li>
            </ul>
          </dd>
        </div>
        {/* LOG DATA */}
        <div className="policy__title">Log Data</div>
        <div className="policy__list">
          <dd>
            We want to inform you that whenever you use our Service, in a case
            of an error in the app we collect data and information (through
            third party products) on your phone called Log Data. This Log Data
            may include information such as your device Internet Protocol (“IP”)
            address, device name, operating system version, the configuration of
            the app when utilizing our Service, the time and date of your use of
            the Service, and other statistics.
          </dd>
        </div>
        {/* COOKIES */}
        <div className="policy__title">Cookies</div>
        <div className="policy__list">
          <dd>
            Cookies are files with a small amount of data that are commonly used
            as anonymous unique identifiers. These are sent to your browser from
            the websites that you visit and are stored on your device's internal
            memory. This Service does not use these “cookies” explicitly.
            However, the app may use third party code and libraries that use
            “cookies” to collect information and improve their services. You
            have the option to either accept or refuse these cookies and know
            when a cookie is being sent to your device. If you choose to refuse
            our cookies, you may not be able to use some portions of this
            Service.
          </dd>
        </div>
        {/* COOKIES */}
        <div className="policy__title">Service Providers</div>
        <div className="policy__list">
          <dd>
            We may employ third-party companies and individuals due to the
            following reasons:
            <ul>
              <li>To facilitate our Service;</li>
              <li>To provide the Service on our behalf;</li>
              <li>To perform Service-related services; or</li>
              <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            We want to inform users of this Service that these third parties
            have access to your Personal Information. The reason is to perform
            the tasks assigned to them on our behalf. However, they are
            obligated not to disclose or use the information for any other
            purpose.
          </dd>
        </div>
        {/* SECURITY */}
        <div className="policy__title">Security</div>
        <div className="policy__list">
          <dd>
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </dd>
        </div>
        {/* Links to Other Sites */}
        <div className="policy__title">Links to Other Sites</div>
        <div className="policy__list">
          <dd>
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </dd>
        </div>
        {/* Children’s Privacy */}
        <div className="policy__title">Children’s Privacy</div>
        <div className="policy__list">
          <dd>
            These Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13 years of age. In the case we discover that a child under 13
            has provided us with personal information, we immediately delete
            this from our servers. If you are a parent or guardian and you are
            aware that your child has provided us with personal information,
            please contact us so that we will be able to do necessary actions.
          </dd>
        </div>
        {/* Changes to This Privacy Policy */}
        <div className="policy__title">Changes to This Privacy Policy</div>
        <div className="policy__list">
          <dd>
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page. This policy is effective as of 2021-08-20
          </dd>
        </div>
        {/* Contact Us */}
        <div className="policy__title">Contact Us</div>
        <div className="policy__list">
          <dd>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us at{" "}
            <span>
              <a href="mailto:growthlabnepal@gmail.com">
                growthlabnepal@gmail.com
              </a>
            </span>
            .
          </dd>
        </div>
      </Wrapper>
    </>
  );
};

export default PrivacyPolicy;
