import React, { createContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SearchBox from "components/Header/SearchBox/SearchBox";
import Cart from "components/Header/Cart/Cart";
import Favorite from "components/Header/Favorite";
import Profile from "components/Header/Profile/Profile";
import Category from "components/Header/Category/Category";
import PocketAcademyLargeLogo from "assets/logo/pocketexam.png";
import PocketAcademySmallLogo from "assets/logo/small-logo.svg";
import SmSearch from "assets/icons/sm-search.svg";
import Container from "common/Container";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { GiHamburgerMenu } from "react-icons/gi";
import NotificationCenter from "./NotificationCenter";
import HeaderSidebar from "./HeaderSidebar";
import { useSelector } from "react-redux";
import { loginSelector } from "pages/AuthenticationPage/Login/LoginSlice";
// scss
import "./index.scss";

export const HeaderContext = createContext(null);

function Header() {
  const [open, setOpen] = useState(false);
  const { isAuthenticated, data: userInfo } = useSelector(loginSelector);
  const history = useHistory();

  // if logout is clicked then will redirect to /login page
  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, [isAuthenticated, history]);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
    document.body.style.overflow = "auto";
  };

  const isMediumScreen = useMediaQuery("(max-width:830px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  // show sidebar only on small screen
  useEffect(() => {
    if (!isSmallScreen) {
      setOpen(false);
    }
  }, [isSmallScreen]);

  return (
    <HeaderContext.Provider
      value={{ isAuthenticated, open, handleClose, userInfo }}
    >
      {/* main header */}
      <div className="header">
        <Container>
          <div className="header__wrapper">
            <div className="header__left">
              {isSmallScreen ? (
                <>
                  <div
                    className="header__hamburgerMenuIcon"
                    onClick={handleOpen}
                  >
                    <GiHamburgerMenu />
                  </div>
                  <Link to="/login" className="header__logo">
                    <img
                      className="header__logo--sm"
                      src={PocketAcademySmallLogo}
                      alt="pocket academy logo"
                    />
                  </Link>
                </>
              ) : (
                <Link to="/login" className="header__logo">
                  <img
                    className="header__logo--lg"
                    src={PocketAcademyLargeLogo}
                    alt="Pocket Academy Logo"
                  />
                </Link>
              )}
              <Category />
              {/* <SearchBox /> */}
            </div>
            <div className="header__right">
              {/* <div className='header__right--smallSearchIcon'>
                <Link to='/mobilesearch/'>
                  <img src={SmSearch} alt='searchIcon' />
                </Link>
              </div>
              <Cart />
              <Favorite /> */}
              {isMediumScreen && isAuthenticated && <NotificationCenter />}
              <Profile />
            </div>
          </div>
        </Container>
        {/* sidebar */}
        <HeaderSidebar />
      </div>
    </HeaderContext.Provider>
  );
}

export default Header;
